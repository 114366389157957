import './App.css';

function App() {
  return (
    <div className="app">
      <div>
        <h1>Work in progress...</h1>
        <h1>By Samuel :)</h1>
      </div>
    </div>
  );
}

export default App;
